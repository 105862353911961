<template>
    <public-frame :menu="false" :theme="dark?'':'white'">
        <div class="page-body overflow-auto">
            <div class=" ctr bg-white body-container d-flex flex-column">
                <div class="flex-grow-1">
                    <div class="flex-in-middle h-100" v-if="single.close_action == '1'">
                        <h1>{{(
                            preset.app && preset.app.program_close_notice)
                            ?($english()? (preset.app.program_close_notice_en || preset.app.program_close_notice):preset.app.program_close_notice)
                            :$t("MESSAGE.EVENT-CLOSED")
                            }}
                        </h1>
                        <br/>
                        <h3><a href="" @click=" window.close();" style="cursor: pointer; text-decoration: none;color: black">{{$t('TITLE.CLOSE')}}</a></h3>
                    </div>
                    <div class="flex-in-middle" v-else>
                        <img :src="single.ending_picture_url" class="responsive-image" alt="">
                    </div>
                </div>
            </div>
        </div>
    </public-frame>
</template>

<script>
import PublicFrame from "@/components/public-frame-dark";
import CommonMixin from "@/common/mixins/common-mixin";

export default {
    name: "play-end-page",
    components: {PublicFrame},
    mixins: [CommonMixin],
    data() {
        return {
        }
    },
    props: {
        dark: {
            type: Boolean, default() {
                return false;
            }
        },
        single: {
            type: Object,
            default() {
                return {};
            }
        },
    },
}
</script>
